<template>
    <div class="font-app">
        <div class="text-center mb-3 h2">
            <div class="pb-2" v-if="headerText && !pendingVerify">
                {{ headerText }}
            </div>

            <img
                @click="$router.push({ name: 'Home' })"
                v-else
                class="auth-logo"
                alt="logo"
                src="@/assets/logo.png"
            />
        </div>
        <!--
        <div v-if="pendingVerify" class="text-center">
            -->
        <div v-if="pendingVerify" class="text-center">
            <h2 class="space-bottom-1">Check your inbox</h2>
            <p class="mb-4">
                Security is one of our top priorities. Before we begin filing
                your taxes, let's confirm your email address.
            </p>
            <div class="mb-5 px-lg-2">
                Click the link we sent to
                <div class="h3 my-0">{{ email }}</div>
                to complete your account set-up.
            </div>

            <p class="pb-1 mb-0">Didn't get the email?</p>
            <btn @click="retryVerify()" app :loading="retryLoading">
                <i v-if="retrySuccess" class="fas fa-check mr-2"></i>
                Send it again
            </btn>
            <span v-if="retryFailure" class="d-flex justify-content-center">
                <p
                    class="text-small text-danger w-lg-50 text-center"
                    v-if="retryFailureCause"
                >
                    {{ retryFailureCause }}
                </p>
                <p class="text-small text-danger w-lg-50 text-center" v-else>
                    An unexpected error occurred. Please try again later.
                </p>
            </span>
            <span v-if="nRetries >= 5" class="d-flex justify-content-center">
                <p class="text-small text-danger w-lg-50 text-center">
                    The email has been sent too many times. If you still aren't
                    receiving it, please try signing up with a different email.
                </p>
            </span>
        </div>
        <div v-else>
            <div
                v-if="pendingReferrerVerify"
                class="d-flex justify-content-center space-top-3 space-bottom-3"
            >
                <loading color="primary" :size="60" />
            </div>
            <div v-else-if="invalidReferrer" class="space-top-2 space-bottom-3">
                <div>
                    <h2 class="text-center mb-5">Invalid Referral Link</h2>
                    <p class="text-center lead">
                        Please make sure that the link is copied correctly and
                        try again
                    </p>
                </div>
            </div>
            <div v-else>
                <validation-observer ref="validator">
                    <div class="mb-4">
                        <text-input
                            v-model="email"
                            validation-rules="required|email"
                        >
                            <template v-slot:label> Email </template>
                        </text-input>

                        <label
                            v-if="showConfirmationMessage"
                            class="ml-1 mt-1 mb-0 custom-field-label"
                            style="font-size: 13px !important"
                        >
                            To protect your info, your email will be confirmed
                        </label>
                    </div>
                    <password-input strict v-model="password">
                        <template v-slot:label> Password </template>
                    </password-input>
                </validation-observer>

                <div class="w-100 mb-4"></div>
                <vue-recaptcha
                    class="g-recaptcha"
                    ref="recaptcha"
                    @verify="captchaVerified"
                    @expired="captchaExpired"
                    sitekey="6Ldv4NEZAAAAAJJ9fIJXbyk7KY3XPrMOH9biWU_r"
                ></vue-recaptcha>
                <div
                    class="text-small text-danger text-center"
                    v-if="needsVerify"
                >
                    Please verify before continuing.
                </div>
                <div class="text-center mt-4">
                    <div class="mb-4">
                        <btn @click="signup" app block :loading="signupLoading">
                            {{ buttonText }}
                        </btn>
                        <div
                            class="error_message h5 p-0 pt-1 m-0 text-center w-100 text-danger"
                            v-if="errors.length"
                        >
                            <i class="fas fa-warning mr-1"></i> {{ errors[0] }}
                        </div>
                    </div>
                    <div :class="extraLinksClass">
                        <div class="mb-3" v-if="signInOption">
                            Already have an account?

                            <a
                                href="javascript:;"
                                @click="$router.push({ name: 'Login' })"
                            >
                                Sign In
                            </a>
                        </div>
                        View our
                        <a
                            href="javascript:;"
                            @click="$router.push({ name: 'PrivacyPolicy' })"
                        >
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import loading from '@/components/loading';
import passwordInput from '@/components/forms/password-input';
import Loading from '../../components/loading.vue';
import { unauthClient } from '@/api/index.js';

export default {
    props: {
        buttonText: {
            type: String,
            default: 'Sign Up',
        },
        signInOption: {
            type: Boolean,
            default: true,
        },
        showConfirmationMessage: {
            type: Boolean,
            default: false,
        },
        extraLinksClass: {
            type: String,
            default: 'larger_helper_text',
        },
        headerText: {
            type: String,
            default: '',
        },
    },
    components: { VueRecaptcha, passwordInput },
    created() {
        if ('referrer_id' in this.$route.query) {
            this.pendingReferrerVerify = true;
            var body = {
                validate_referrer: true,
                referrer_id: this.$route.query.referrer_id,
                referrer_key: this.$route.query.referrer_key,
            };
            return unauthClient
                .post('/referrals/', body)
                .then(() => {
                    this.pendingReferrerVerify = false;
                })
                .catch(() => {
                    this.pendingReferrerVerify = false;

                    this.invalidReferrer = true;
                });
        }
    },
    data() {
        return {
            email: null,
            email_bool: true,
            password: null,
            errors: [],
            disablebTag: true,
            needsVerify: false,
            animated: false,
            pendingVerify: false,
            retryLoading: false,
            retrySuccess: false,
            retryFailure: false,
            retryFailureCause: null,
            nRetries: 0,
            signupLoading: false,
            pendingReferrerVerify: false,
            invalidReferrer: false,
            mounting: true,
        };
    },
    mounted() {
        this.$nextTick().then(() => {
            setTimeout(() => {
                this.mounting = false;
            }, 150);
        });
    },
    methods: {
        clickHandler() {
            const self = this;
            self.animated = true;
            setTimeout(() => {
                self.animated = false;
            }, 1000);
        },
        signup() {
            if (this.disablebTag) {
                this.needsVerify = true;
                return;
            }
            this.$refs.validator.validate();
            if (!this.$refs.validator.flags.valid) {
                return;
            }
            this.signupLoading = true;
            var referrerInfo = {};
            if ('referrer_id' in this.$route.query) {
                referrerInfo.referrer_id = this.$route.query.referrer_id;
                referrerInfo.referrer_key = this.$route.query.referrer_key;
            }

            // self.$refs.recaptcha.reset();
            this.$store
                .dispatch('registerUser', {
                    email: this.email,
                    password: this.password,
                    password_confirm: this.password_confirm,
                    retry: false,
                    ...referrerInfo,
                })
                .then(() => {
                    this.signupLoading = false;
                    this.pendingVerify = true;
                })
                .catch((error) => {
                    if (this.errors.length === 0) {
                        this.errors.push(error.response.data);
                    } else {
                        this.clickHandler();
                    }
                    this.signupLoading = false;
                });
        },
        retryVerify() {
            if (this.nRetries >= 5) {
                return;
            }

            this.retryLoading = true;
            this.retrySuccess = false;
            this.retryFailure = false;
            return this.$store
                .dispatch('registerUser', {
                    email: this.email,
                    password: this.password,
                    password_confirm: this.password_confirm,
                    retry: true,
                })
                .then(() => {
                    this.retryLoading = false;
                    this.retrySuccess = true;
                    this.nRetries++;
                })
                .catch((err) => {
                    this.retryFailure = true;
                    if (
                        err.response.data ==
                        'This email has already been verified'
                    ) {
                        this.retryFailureCause = err.response.data;
                    }
                    this.retryLoading = false;
                });
        },
        captchaVerified() {
            this.disablebTag = false;
            this.needsVerify = false;
            // this.$refs.recaptcha.execute();
        },
        captchaExpired: function () {
            this.$refs.recaptcha.reset();
        },
    },
};
</script>
