<template>
    <auth-wrapper>
        <SignupBase />
    </auth-wrapper>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import loading from '@/components/loading';
import Loading from '../../components/loading.vue';
import { unauthClient } from '@/api/index.js';
import authWrapper from './auth-wrapper';
import SignupBase from '@/views/auth/signup_base';

export default {
    components: {
        VueRecaptcha,
        loading,
        Loading,
        SignupBase,
        authWrapper,
    },
    created() {
        if ('referrer_id' in this.$route.query) {
            this.pendingReferrerVerify = true;
            var body = {
                validate_referrer: true,
                referrer_id: this.$route.query.referrer_id,
                referrer_key: this.$route.query.referrer_key,
            };
            return unauthClient
                .post('/referrals/', body)
                .then(() => {
                    this.pendingReferrerVerify = false;
                })
                .catch(() => {
                    this.pendingReferrerVerify = false;

                    this.invalidReferrer = true;
                });
        }
    },
    data() {
        return {
            email: null,
            email_bool: true,
            password: null,
            errors: [],
            needsVerify: false,
            animated: false,
            pendingVerify: false,
            retryLoading: false,
            retrySuccess: false,
            retryFailure: false,
            retryFailureCause: null,
            nRetries: 0,
            signupLoading: false,
            pendingReferrerVerify: false,
            invalidReferrer: false,
            mounting: true,
        };
    },
    mounted() {
        this.$nextTick().then(() => {
            setTimeout(() => {
                this.mounting = false;
            }, 150);
        });
    },
    methods: {
        clickHandler() {
            const self = this;
            self.animated = true;
            setTimeout(() => {
                self.animated = false;
            }, 1000);
        },
        redirectToLogin: function () {
            this.$router.push({ name: 'Login' });
        },
        signup() {
            this.$refs.validator.validate();
            if (!this.$refs.validator.flags.valid) {
                return;
            }

            this.signupLoading = true;
            var referrerInfo = {};
            if ('referrer_id' in this.$route.query) {
                referrerInfo.referrer_id = this.$route.query.referrer_id;
                referrerInfo.referrer_key = this.$route.query.referrer_key;
            }

            // self.$refs.recaptcha.reset();
            this.$store
                .dispatch('registerUser', {
                    email: this.email,
                    password: this.password,
                    password_confirm: this.password_confirm,
                    retry: false,
                    ...referrerInfo,
                })
                .then(() => {
                    this.signupLoading = false;
                    this.pendingVerify = true;
                })
                .catch((error) => {
                    if (this.errors.length === 0) {
                        this.errors.push(`A ${error.response.data.email[0]}`);
                    } else {
                        this.clickHandler();
                    }
                    this.signupLoading = false;
                });
        },
        retryVerify() {
            if (this.nRetries >= 5) {
                return;
            }

            this.retryLoading = true;
            this.retrySuccess = false;
            this.retryFailure = false;
            return this.$store
                .dispatch('registerUser', {
                    email: this.email,
                    password: this.password,
                    password_confirm: this.password_confirm,
                    retry: true,
                })
                .then(() => {
                    this.retryLoading = false;
                    this.retrySuccess = true;
                    this.nRetries++;
                })
                .catch((err) => {
                    this.retryFailure = true;
                    if (
                        err.response.data ==
                        'This email has already been verified'
                    ) {
                        this.retryFailureCause = err.response.data;
                    }
                    this.retryLoading = false;
                });
        },
        captchaVerified() {
            this.needsVerify = false;
        },
        captchaExpired: function () {
            this.$refs.recaptcha.reset();
        },
    },
};
</script>
