<template>
    <text-input
        :input-type="visible ? 'text' : 'password'"
        :value="value"
        @input="$emit('input', $event)"
        :validation-rules="rules"
    >
        <template v-slot:label> <slot name="label"> </slot> </template>

        <template v-slot:input-icon-append>
            <i style="cursor: pointer" @click="visible = !visible" :class="`fas fa-${inputIcon} fa-lg`">
            </i>
        </template>
    </text-input>
</template>

<script>
export default {
    props: {
        value: { required: true },
        strict: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localValue: '',
            visible: false,
        };
    },
    computed: {
        rules() {
            if (this.strict) {
                return 'required|password';
            } else {
                return 'required';
            }
        },
        inputIcon() {
            if (this.visible) {
                return 'eye-slash';
            } else {
                return 'eye';
            }
        },
    },
};
</script>
