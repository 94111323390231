<template>
    <div class="auth-page-container">
        <div class="container">
            <div class="auth-card">
                <slot> </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>
